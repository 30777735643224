$primary: #A30000;
$error: #7C0808;
$secondary: #FFB800;
$primary-alt: #E91212;
$secondary-alt: #FEC83C;
$colors: (
	'primary':$primary, 
	'error':$error, 
	'secondary':$secondary,
	'primary-alt':$primary-alt,
	'secondary-alt':$secondary-alt
)